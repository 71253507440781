import {
  ConvertCtoF,
  convertKgM3ToLbYd3,
  ConvertKgToLb,
  ConvertMMtoINCH,
  ConvertMPaKgToPSILb,
  ConvertMPAToPSIRounded,
  calculatePercentage,
  ConvertLbPerYd3ToKgPerM3,
  ConvertINCHtoMM,
  ConvertMPAToPSIUnrounded,
  ConvertPSIToMPAUnrounded,
  ConvertMPaKgToPSILbUnrounded,
  ConvertPSILbToMPAKgUnrounded,
  ConvertFtoCUnrounded,
  ConvertCtoFUnrounded,
  ConvertMMtoINCHUnrounded,
  roundUpToDecimal,
  ConvertLbPerYd3ToKgPerM3Unrounded,
  convertKgM3ToLbYd3Unrounded,
  comparedSort,
  getCurrentPageRows,
} from '../../Common/Helpers/GeneralHelpers'
import {
  AlertObject,
  DigestedBatchActual,
  DigestedMixDesign,
  FreshPropertyNumberKeys,
  FreshPropertyObject,
  MixGroupConditions,
  MixDesignFromBatchByMix,
  MixGroupStatus,
  MixVariationBatchList,
  Units,
  MixGroupVariation,
  CO2DosageUnit,
  PlantOptions,
  CustomerOptions,
  IMaterial,
  MaterialSubTypeOptions,
  MaterialTypeOptions,
  IMaterialTypeMetadata,
  ICompareAndAnalyzeMixDesigns,
  PropertyUnit,
  MaterialObject,
  CementComponent,
  PlantComposition,
  IDigestedMaterial,
  IMixSelection,
  ICommissionReportVariation,
  VariationTypes,
} from './Types'
import warningUnder30 from '../Assets/AnalysisSamplesWarning30.png'
import warningUnder15 from '../Assets/AnalysisSamplesWarning15.png'

/** By zed_0xff - Own work, CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=23105814 */
import transparentBg from '../Assets/TransparentBackground.png'
import PlantCardClass from './PlantCardClass'
import {
  axisLabelsWithImperialUnit,
  colorAxisColors,
  dataPointsSymbols,
  commissioningTrialValues,
  axisLabelsWithMetricUnit,
  getTooltipBatchSampleUnitPrecision,
  getBatchSampleUnitPrecision,
} from '../Constants/AnalysisConstants'

import { mean } from 'mathjs'

import {
  AnnotationsOptions,
  ColorAxisOptions,
  DashStyleValue,
  Options,
  Point,
  PointMarkerOptionsObject,
  SeriesColumnOptions,
  SeriesScatterOptions,
  SeriesSplineOptions,
  YAxisPlotLinesOptions,
} from 'highcharts'

import { GraphFilter } from './GraphFilterClass'
import { CellBehavior, ISimpleTableSettings } from '../../Common/Logic/Types'
import { IMixGroupVariationTableData } from '../Components/MixGroupVariationTable/MixGroupVariationTable'
import { getCO2Dosage, getCO2DosagePrecision } from '../Helpers/BaleenHelpers'
import {
  activeTag,
  archivedTag,
  co2Tag,
  noCO2Tag,
} from '../Constants/CommonConstants'
import cloneDeep from 'lodash.clonedeep'
import {
  getPropertyValue,
  sortGraphDataAndLabels,
} from '../Helpers/CommissionReportHelpers'

/**Convert date date string (ex. C# DateTime.Date) to desired format
 *
 * @param {string} date
 * @param {string} format
 * @param {string} divider
 * @returns {string}
 */
export function getFormattedDate(
  date: string,
  format: string,
  divider: string,
  isUTC: boolean
) {
  let month
  let day
  let year
  if (isUTC) {
    const dateJS = new Date(Date.parse(date))
    month =
      dateJS.getUTCMonth() > 8
        ? dateJS.getUTCMonth() + 1
        : '0' + (dateJS.getUTCMonth() + 1)
    day =
      dateJS.getUTCDate() > 9 ? dateJS.getUTCDate() : '0' + dateJS.getUTCDate()
    year = dateJS.getUTCFullYear()
  } else {
    const dateJS = new Date(Date.parse(date.slice(0, -1)))
    month =
      dateJS.getMonth() > 8
        ? dateJS.getMonth() + 1
        : '0' + (dateJS.getMonth() + 1)
    day = dateJS.getDate() > 9 ? dateJS.getDate() : '0' + dateJS.getDate()
    year = dateJS.getFullYear()
  }

  if (format === 'MMDDYYYY') {
    return getDateInMMDDYYYYFormat(day, month, year, divider)
  } else if (format === 'YYYYMMDD') {
    return getDateInYYYYMMDDFormat(day, month, year, divider)
  }
  return ''
}

/** Convert date string (ex.: C# DateTime.Date) to MM/DD/YYYY format
 *
 * @param {string|number} day
 * @param {string|number} month
 * @param {string|number} year
 * @param {string} divider
 * @returns {string}
 */
export default function getDateInMMDDYYYYFormat(
  day: string | number,
  month: string | number,
  year: string | number,
  divider: string
) {
  return month + divider + day + divider + year
}

/** Convert date string (ex.: C# DateTime.Date) to YYYY-MM-DD format
 *
 * @param {string|number} day
 * @param {string|number} month
 * @param {string|number} year
 * @param {string} divider
 * @returns {string}
 */
export function getDateInYYYYMMDDFormat(
  day: string | number,
  month: string | number,
  year: string | number,
  divider: string
) {
  return year + divider + month + divider + day
}

/** Digest the mix designs as returned by GetMixDesignsByBatch() from DataHelpers.js
 * The data is presented in imperial units
 */
export function digestMixDesigns(
  mixDesignsByBatch: Array<MixDesignFromBatchByMix>
) {
  const mixDesignData: Array<DigestedMixDesign> = []
  mixDesignsByBatch?.forEach(data => {
    let freshProperties: Array<FreshPropertyObject> = []
    const uniqueProductionDates = new Set<string>()
    let totalBatches = 0
    data.mixVariations.forEach((variation: MixGroupVariation) => {
      totalBatches += variation.sampleCount
      variation.variationId = `${data.mixDesignId}-${
        variation.cementReductionPercent
      }-${variation.cO2Dosage}${getCO2DosageUnitLabel(
        variation.cO2DosageUnit,
        false
      )}`
      variation.mixCode = `${data.mixCode}-${
        variation.cementReductionPercent
      }-${variation.cO2Dosage}${getCO2DosageUnitLabel(variation.cO2DosageUnit)}`
      variation.interval = (data.strengthIntervalDays ?? 0) + ' days'
      variation.designStrength = ConvertMPAToPSIUnrounded(
        data.designStrengthMpa
      )
      variation.condition = data.isCO2Design
        ? MixGroupConditions.CO2
        : MixGroupConditions.NOCO2
      variation.averageStrength = ConvertMPAToPSIUnrounded(
        variation.averageStrengthMpa
      ) as number
      variation.requiredStrength = ConvertMPAToPSIUnrounded(
        variation.requiredStrengthMpa
      ) as number
      variation.status = data.isActiveMixGroup
        ? MixGroupStatus.ACTIVE
        : MixGroupStatus.ARCHIVED
      variation.mixDesignId = data.mixDesignId
      variation.producer = data.divisionName
      variation.locationName = data.plantName
      variation.freshProperties = freshProperties
      variation.productionDates = Array.from(uniqueProductionDates).sort(
        comparedSort
      )
      variation.scmPercent = data.scmPercent
      variation.cementAmountTotal = convertKgM3ToLbYd3Unrounded(
        data.cementitiousContentKgPerM3
      )
      variation.cementitiousEfficiency = ConvertMPaKgToPSILbUnrounded(
        variation.cementitiousEfficiency
      )
      variation.waterCementRatio = data.waterCementRatio
      variation.totalYield = data.yieldUnitless
      variation.condition = data.isCO2Design
        ? MixGroupConditions.CO2
        : MixGroupConditions.NOCO2
      variation.interval = (data.strengthIntervalDays ?? 0) + ' days'
      variation.isMetric = false
      variation.cO2DosageLabel =
        variation.cO2Dosage !== null
          ? getCO2Dosage(variation.cO2Dosage, variation.cO2DosageUnit, false)
          : null
      variation.cO2DosageUnitLabel =
        variation.cO2DosageLabel !== null &&
        variation.cO2DosageLabel !== undefined
          ? getCO2DosageUnitLabel(variation.cO2DosageUnit, false)
          : ''
      const cO2DosageForVariationIdLabel =
        variation.cO2Dosage !== null
          ? variation.cO2DosageLabel?.toFixed(
              getCO2DosagePrecision(variation.cO2DosageUnit, false)
            )
          : null
      variation.variationIdLabel = `${extractMixCodeFromVariationName(
        variation.mixCode
      )}-${variation.cementReductionPercent}-${cO2DosageForVariationIdLabel}${
        variation.cO2DosageUnitLabel
      }`
    })

    // arrange mix design data
    mixDesignData.push({
      divisionId: data.divisionId,
      plantId: data.plantId,
      mixDesignId: data.mixDesignId,
      producer: data.divisionName,
      mixCode: data.mixCode,
      locationName: data.plantName,
      locationAddress: data.locationAddress,
      preCCMixCode: data.preCCMixCode,
      totalCylinders: data.totalStrengthReadings,
      totalBatches: totalBatches,
      designStrength: ConvertMPAToPSIUnrounded(data.designStrengthMpa),
      requiredStrength: ConvertMPAToPSIUnrounded(
        data.requiredStrengthMpa
      ) as number,
      averageStrength: ConvertMPAToPSIUnrounded(
        data.averageStrengthMpa
      ) as number,
      standardDeviation: ConvertMPAToPSIUnrounded(
        data.strengthReadingStd28Days
      ) as number,
      overDesign: Number(data.overDesign).toFixed(2),
      interval: data.strengthIntervalDays
        ? data.strengthIntervalDays + ' days'
        : '',
      condition: data.isCO2Design
        ? MixGroupConditions.CO2
        : MixGroupConditions.NOCO2,
      waterCementRatio: data.waterCementRatio,
      cementSupplier: data.cementSupplier,
      totalSamples28Days: data.totalSamples28Days,
      freshProperties,
      cementitiousEfficiency: ConvertMPaKgToPSILb(data.cementitiousEfficiency),
      cementAmountTotal: convertKgM3ToLbYd3Unrounded(
        data.cementitiousContentKgPerM3
      ),
      percentScm: data.scmPercent,
      totalYield: data.yieldUnitless,
      productionDates: Array.from(uniqueProductionDates),
      inCommissioning: data.isInCommissioning,
      cO2Dosage: data.cO2Dosage,
      cO2DosageUnit: data.cO2DosageUnit,
      cementReductionPercent: data.cementReductionPercent,
      variations: data.mixVariations,
      status: data.isActiveMixGroup
        ? MixGroupStatus.ACTIVE
        : MixGroupStatus.ARCHIVED,
      isMetric: false,
    })
  })

  return { mixDesignData }
}

export function GetAlerts(
  mixDesignData: Array<DigestedMixDesign> | null | undefined
) {
  if (mixDesignData === null || mixDesignData === undefined) {
    return []
  } else {
    return createAlerts(mixDesignData)
  }
}

export function createAlerts(
  mixDesignData: Array<DigestedMixDesign>
): Array<AlertObject> {
  const alerts: Array<AlertObject> = []
  mixDesignData.forEach(data => {
    if (Number(data.overDesign) > 1.2) {
      alerts.push({
        name: 'OD > 1.2',
        mixDesignId: data.mixDesignId,
        mixCode: data.mixCode,
        producer: data.producer,
        description: 'Reduce the over design for ',
      })
    }
    if (
      data.condition === MixGroupConditions.CO2 &&
      data.preCCMixCode === null
    ) {
      alerts.push({
        name: 'Missing Information',
        mixDesignId: data.mixDesignId,
        mixCode: data.mixCode,
        producer: data.producer,
        description: 'Add the control condition mix code for ',
      })
    }
    if (
      !(
        data.mixCode ||
        data.designStrength ||
        data.waterCementRatio ||
        data.cementSupplier ||
        data.producer ||
        data.interval
      )
    ) {
      alerts.push({
        name: 'Missing Information',
        mixDesignId: data.mixDesignId,
        mixCode: data.mixCode,
        producer: data.producer,
        description: 'Add the missing mix design information for',
      })
    }
    if (
      data.totalSamples28Days !== 0 &&
      data.requiredStrength > data.averageStrength
    ) {
      alerts.push({
        name: 'Under Designed',
        mixDesignId: data.mixDesignId,
        mixCode: data.mixCode,
        producer: data.producer,
        description: 'Check out more on ',
      })
    }
    if (data.totalBatches < 30) {
      alerts.push({
        name: 'Not Enough Samples',
        mixDesignId: data.mixDesignId,
        mixCode: data.mixCode,
        producer: data.producer,
        description: 'Only ' + data.totalBatches + ' samples available for ',
      })
    }
  })
  return alerts
}

/**
 * Takes a sorted array of values, splits them into 4 equal parts, and returns the largest value from each of those quarters
 * @param freshPropertyValueArray Sorted array of values of a fresh property, guarded so that null values are 0s
 * @returns
 */
export const getMaxRanges = (freshPropertyValueArray: Array<number>) => {
  let value = 0
  let subArrayToStoreColors: Array<Array<number>> = [[], [], [], []]
  const subArrayLength = Math.ceil(freshPropertyValueArray.length / 4)

  const maxRanges = []
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < subArrayLength; j++) {
      value = freshPropertyValueArray[j + i * subArrayLength]
      //potential problem: if the initial quadrant is filled with nulls (all 0s)
      //then the maxRanges's value will be undefined (and maybe all of them will be)
      if (!value) continue
      subArrayToStoreColors[i].push(value)
    }
    maxRanges.push(Math.max(...subArrayToStoreColors[i]))
  }
  return maxRanges
}

/**
 *
 * @param row An array with four values, being defined as [TestNumber, batchStrength, freshPropertyValue, and batchId].
 * The TestNumber is from an iterator, the batchStrength is defined in PSI,
 * the freshProperty can be any in the freshPropertyObject, and the batchId is from kelowna
 * @param maxRanges The return of the getMaxRanges() function in this file, representing the four largest numbers
 * after the
 * @param colorSet An array containing 4 string hex colour codes
 */
export const getMixColor = (
  row: [any, any, number, any],
  maxRanges: Array<number>,
  colorSet: [string, string, string, string]
) => {
  if (row[2] <= maxRanges[0]) return colorSet[0]
  else if (row[2] <= maxRanges[1]) return colorSet[1]
  else if (row[2] <= maxRanges[2]) return colorSet[2]
  else if (row[2] <= maxRanges[3]) return colorSet[3]
  return '#000000'
}

/** Digest the BatchTestSamples as returned from the BatchTestSamplesAndStrengthReadingsByMixId/{mixId} endpoint  */
export function digestBatchTestSamples(
  batchTestSamples: MixVariationBatchList,
  digestedMixDesign: DigestedMixDesign,
  variation: MixGroupVariation
) {
  const batchActualsData: Array<DigestedBatchActual> = []

  batchTestSamples.forEach(sample => {
    // build new batch actuals with digested data
    batchActualsData.push({
      mixDesignId: digestedMixDesign.mixDesignId,
      ticketId: sample.externalTicketId,
      batchTestSampleId: sample.batchSampleId,
      producer: digestedMixDesign.producer,
      mixCode: getVariationMixCodeLabel(
        variation.mixCode,
        getCO2DosageUnitLabel(variation.cO2DosageUnit)
      ),
      variationId: variation.variationId,
      totalCylinders: sample.strengthReadings.length, //strengthReadings is an array of cylinder readings
      productionDate: sample.productionDate,
      slump: ConvertMMtoINCH(sample.slumpMm),
      air: sample.airContent,
      concreteTemperature: ConvertCtoF(sample.concreteTemperature),
      cementContent: ConvertKgToLb(sample.cementContent),
      strengthAvg7Days: ConvertMPAToPSIRounded(sample.averageStrength7Days),
      strengthAvg28Days: ConvertMPAToPSIRounded(sample.averageStrength28Days),
      densityKgPerM3: sample.densityKgPerM3,
      notes: sample.notes,
    })
  })

  return batchActualsData
}

/* Function to get the slope and intercept for the strength development linear regression,
which will then be displayed as logarithmic curve on the graph*/
export function GetSlopeAndIntercept(data: Array<{ x: number; y: number }>) {
  let slope, intercept
  let SumX = 0
  let SumY = 0
  let SumXX = 0
  let SumXY = 0
  let totalSamples = data.length

  data.forEach(element => {
    let x = element.x
    let y = element.y
    SumX = SumX + x
    SumY = SumY + y
    SumXX = SumXX + x * x
    SumXY = SumXY + x * y
  })

  /* Calculate slope and intercept from linear regression of the data */
  slope =
    (totalSamples * SumXY - SumX * SumY) / (totalSamples * SumXX - SumX * SumX)

  intercept = (SumY - slope * SumX) / totalSamples

  return { slope, intercept }
}

/**
 * Returns watermark for analysis graph depending on how many samples are available
 * for the selected mix variations.
 * @param {Object} selectedMixVariations
 * @returns {string} reference to appropriate watermark's png file or null.
 */
export const getGraphWatermark = (
  selectedMixVariations: Array<MixGroupVariation>
) => {
  if (selectedMixVariations.some(design => design.sampleCount < 15)) {
    return warningUnder15
  } else if (selectedMixVariations.some(design => design.sampleCount < 30)) {
    return warningUnder30
  }

  /** Highcharts will not update the chart background image with null/undefined.
   * In order to remove a warning when a mix is unselected, a 75 byte transparent image is applied in its place.
   */
  return transparentBg
}

/**
 *
 *
 * @param {PlantCardClass} plant
 * @returns {String []} Array of errors that may be missing
 */
export function getPlantMissingFields(plant: PlantCardClass) {
  const importantFields = {
    displayName: 'Display Name',
    averageLoadSizeM3: 'Average Load Size',
    averageCementLoadingKgPerM3: 'Average Cement Loading',
    residentialPercent: 'Residential Percent',
    commercialPercent: 'Commercial Percent',
    dotPercent: 'DOT Percent',
    averageCementCut: 'Average Cement Cut',
    cO2DosePercent: 'CO2 Dose Percent',
    cementPlantId: 'Cement Plant',
  }

  const importantKeys = Object.keys(importantFields) as Array<
    | 'displayName'
    | 'averageLoadSizeM3'
    | 'averageCementLoadingKgPerM3'
    | 'residentialPercent'
    | 'commercialPercent'
    | 'dotPercent'
    | 'averageCementCut'
    | 'cO2DosePercent'
    | 'cementPlantId'
  >

  const alerts: Array<string> = []

  importantKeys.forEach(key => {
    if (plant[key] === null) {
      alerts.push(importantFields[key])
    }
  })

  return alerts
}

/**
 *
 * @param unitSystem The unit system to get the proper StrengthUnit
 * @returns The text for the proper strength unit
 */
export function getStrengthUnit(unitSystem: Units) {
  if (unitSystem === Units.Metric) {
    return 'MPa'
  } else if (unitSystem === Units.Imperial) {
    return 'psi'
  } else {
    return 'kg/cm\u00b2'
  }
}

/**
 * Function to get a Horizontal Spline Series for highcharts instead of using a plotline, since highcharts has no option to have
 * their plolines be selectable in the legend.
 * @param yValue Where the horizontal line should fall on
 * @param label The name of the horizontal line in the legend
 * @param color Color of the line
 * @param id id given to the horizontal line, to use as reference later
 * @param dashStyle One of the Dash Style Values set by HighCharts
 * @param minXValue If you're using your main xAxis, this should be the minimum value. Defaults to 0
 * @param maxXValue If you're using your main xAxis, this should be the maximum value. Defaults to 1
 * @param lineWidth The width of the line rendered, defaults to 2
 * @param xAxis The xAxis you want this line to present over, defaults to `line_axis`
 * @returns `SeriesSplineOptions` object shape, with marker, dataLabels, and mousetracking disabled
 */
export function getHorizontalLineSeriesHighCharts(
  yValue: number,
  label: string,
  color?: string,
  id?: string,
  dashStyle?: DashStyleValue,
  minXValue: number = 0,
  maxXValue: number = 1,
  lineWidth: number = 2,
  xAxis: string = 'line_axis'
): SeriesSplineOptions {
  return {
    type: 'spline',
    data: [
      { y: yValue, x: minXValue },
      { y: yValue, x: maxXValue },
    ],
    showInLegend: true,
    name: label,
    xAxis: xAxis,
    lineWidth: lineWidth,
    color: color,
    id: id,
    dashStyle: dashStyle,
    enableMouseTracking: false,
    marker: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
  }
}

/**
 * Helper function to reorder seven day and 28 day strength values to get proper percentage increase/decrease label
 * @param keys an array containing 2 strings in the form: mixDesignId-mixDesignCondition
 * @param values an array containing 2 numbers for the strengths
 */

export function reorderStrengthVals(keys: any, values: any) {
  const condition1 = keys[0].slice(-6)
  const condition2 = keys[1].slice(-6)
  // if the conditions arent the same and CO2 isnt the first value, swap them
  if (condition1 !== condition2) {
    if (condition1 === MixGroupConditions.NOCO2) {
      const temp = values[0]
      values[0] = values[1]
      values[1] = temp
    }
  }
}

/**
 * Helper function to get the overdesign percent label for a given mix, defaults to 28-day strength.
 *
 * Throws an error if you try and calculate the overdesign for seven day strength without passing the mean strengths for seven days
 *
 * @param mixDesign Mix design you'd like to get the over design for
 * @param meanSevenDayStrength Object with the key being the MixDesignId and condition (eg 7801-CO2) and the value being the mean of the seven day strengths of that mix design
 * @param sevenDay Whether you want the overDesign for seven day strengths (if false, gets overdesign for 28 day strength)
 * @returns A string with the overdesign in percentage form
 */
export function getOverDesignPercentLabel(
  meanSevenDayStrength: { [index: string]: number } | null,
  avgTwentyEightDayStrength: { [index: string]: number },
  reorderStrengthValues: Function,
  sevenDay = false
): string {
  let sevenDayStrengthValue = Object.values(meanSevenDayStrength)
  let avgTwentyEightDayStrengthValue = Object.values(avgTwentyEightDayStrength)
  let sevenDayStrengthKeys = Object.keys(meanSevenDayStrength)
  let avgTwentyEightDayKeys = Object.keys(avgTwentyEightDayStrength)
  if (sevenDayStrengthKeys.length === 2) {
    reorderStrengthValues(sevenDayStrengthKeys, sevenDayStrengthValue)
  }

  if (avgTwentyEightDayKeys.length === 2) {
    reorderStrengthValues(avgTwentyEightDayKeys, avgTwentyEightDayStrengthValue)
  }
  let sevenDayCalculation =
    calculatePercentage(sevenDayStrengthValue[0], sevenDayStrengthValue[1]) || 0
  let twentyEightDayCalculation =
    calculatePercentage(
      avgTwentyEightDayStrengthValue[0],
      avgTwentyEightDayStrengthValue[1]
    ) || 0

  if (
    sevenDayStrengthValue.length !== 2 ||
    avgTwentyEightDayStrengthValue.length !== 2
  ) {
    return ''
  }

  if (sevenDay && meanSevenDayStrength !== null) {
    return `${sevenDayCalculation}%`
  }

  if (sevenDay && meanSevenDayStrength === null) {
    throw new Error(
      "Can't calculate overdesign for sevenDayStrength without meanSevenDayStrength object"
    )
  }

  return `${twentyEightDayCalculation}%`
}

/**
 * Helper function to calculate seven day means for an array of mix designs
 *
 * @param selectedMixVariations The set mix variations you wish to calculate their seven day means for
 * @param unit Unit as defined by the enum. Currently only imperial is considered as an option but this unit is used for future options
 * @returns a dict of the seven day strengths, keyed by the Mix Design's ID and condition (eg 7801-CO2)
 */
export function getSevenDayMeans(
  selectedMixVariations: MixGroupVariation[],
  unit: Units = Units.Imperial
): { [index: number]: number } {
  const meanSevenDayStrengths: { [index: string]: number } = {}

  selectedMixVariations.forEach(mixVariation => {
    const meanSevenDayStrengthsKey: string =
      mixVariation.variationIdLabel + '-' + mixVariation.condition
    // get only the seven day strengths
    let sevenDayStrengths = mixVariation.freshProperties
      .filter(freshProp => freshProp.batchInterval === 7)
      .map(filteredFreshProperty => filteredFreshProperty.batchStrength)
    // map the respective seven day strength to its mixDesignId
    if (sevenDayStrengths.length !== 0) {
      meanSevenDayStrengths[meanSevenDayStrengthsKey] =
        unit === Units.Metric
          ? Number(mean(sevenDayStrengths).toFixed(2))
          : Number(mean(sevenDayStrengths).toFixed(0))
      // if the array is empty, mean() throws an error so we skirt around that by just assigning the mean to 0
    } else {
      meanSevenDayStrengths[meanSevenDayStrengthsKey] = 0
    }
  })

  return meanSevenDayStrengths
}

/**
 * Helper function to calculate seven day means for an array of mix designs
 *
 * @param selectedMixDesigns The set mix designs you wish to calculate their seven day means for
 * @param unit Unit as defined by the enum. Currently only imperial is considered as an option but this unit is used for future options
 * @returns a dict of the seven day strengths, keyed by the Mix Design's ID and condition (eg 7801-CO2)
 */
export function getTwentyEightDayAvgStrengths(
  selectedMixDesigns: MixGroupVariation[],
  unit: Units = Units.Imperial
): { [index: number]: number } {
  const meanTwentyEightDayStrengths: { [index: string]: number } = {}

  selectedMixDesigns.forEach(mixDesign => {
    const meanTwentyEightDayStrengthsKey: string =
      mixDesign.variationIdLabel + '-' + mixDesign.condition
    // get only the seven day strengths
    let twentyEightDayStrengths = mixDesign.freshProperties
      .filter(freshProp => freshProp.batchInterval === 28)
      .map(filteredFreshProperty => filteredFreshProperty.batchStrength)
    // map the respective seven day strength to its mixDesignId
    if (twentyEightDayStrengths.length !== 0) {
      meanTwentyEightDayStrengths[meanTwentyEightDayStrengthsKey] =
        unit === Units.Metric
          ? Number(mean(twentyEightDayStrengths).toFixed(2))
          : Number(mean(twentyEightDayStrengths).toFixed(0))
      // if the array is empty, mean() throws an error so we skirt around that by just assigning the mean to 0
    } else {
      meanTwentyEightDayStrengths[meanTwentyEightDayStrengthsKey] = 0
    }
  })

  return meanTwentyEightDayStrengths
}

/**
 * Creates an Annotation object for a given mix design with a white background, expects the point that it will be hovering over is a mean of both the 7 and 28 day strength.
 *
 * Looks for point with label `<mixDesignId>_7_day` for seven day strength and `<mixDesignId>_28_day` for 28 day strengths
 *
 * @param mixDesign Mix design you want to annotate with a over design percent
 * @param meanSevenDayStrength Dict of mean seven day strengths
 * @returns Returns an AnnotationsOptions that displays the over design of a given mix for it's 7 and 28 day strengths.
 */
function createCo2BarGraphAnnotationOption(
  mixVariation: MixGroupVariation,
  meanSevenDayStrength: { [index: string]: number },
  avgTwentyEightDayStrength: { [index: string]: number }
): AnnotationsOptions {
  return {
    draggable: '',
    labelOptions: {
      verticalAlign: 'top',
      crop: false,
      useHTML: true,
      backgroundColor: 'white',
      borderColor: 'white',
    },
    labels: [
      {
        //references the "point" that is the column for this mix design
        point: `${mixVariation.variationIdLabel}_7_day`,
        text: getOverDesignPercentLabel(
          meanSevenDayStrength,
          avgTwentyEightDayStrength,
          reorderStrengthVals,
          true
        ),
        distance: 20,
        verticalAlign: 'top',
      },
      {
        point: `${mixVariation.variationIdLabel}_28_day`,
        text: getOverDesignPercentLabel(
          meanSevenDayStrength,
          avgTwentyEightDayStrength,
          reorderStrengthVals,
          false
        ),
        distance: 20,
        verticalAlign: 'top',
      },
    ],
  } as AnnotationsOptions
}

/**
 * Function to get the bar graph options, quite self explanatory. Adds in the horizontal lines for design strength
 * (excludes required strength while the mixGrouping functionality isn't available).
 * Calculates the mean strength for the 7-day data since it's not available from the backend.
 *
 * @param selectedMixVariations Array of selected mix variations
 * @param unit Unit as defined by the enum. Currently only imperial is considered as an option but this unit is used for future options
 * @returns HighChart Options for a bar graph, with lines representing design strength for CO2 Mixes
 */
export function getBarGraphOptions(
  selectedMixVariations: MixGroupVariation[],
  unit: Units = Units.Imperial
): Options {
  let strengthUnit = getStrengthUnit(unit)

  const graphWatermark = getGraphWatermark(selectedMixVariations)

  /** Dictionary to hold the mean for seven day strengths, keyed by mix variation id and condition (eg 5050-2-0.1-%-CO₂)*/
  let meanSevenDayStrength: { [index: string]: number } = getSevenDayMeans(
    selectedMixVariations,
    unit
  )

  /** Dictionary to hold the avg for twenty-eight day strengths, keyed by variation id and condition (eg 5050-2-0.1-%-CO₂)*/
  let avgTwentyEightDayStrength: {
    [index: string]: number
  } = getTwentyEightDayAvgStrengths(selectedMixVariations, unit)

  /** Only mix designs with "NOCO2" as their condition  */
  let noCO2MixVariations = selectedMixVariations.filter(
    mixVariation => mixVariation.condition === MixGroupConditions.NOCO2
  )

  let co2MixVariations = selectedMixVariations.filter(
    mixVariation => mixVariation.condition === MixGroupConditions.CO2
  )

  // get "plotLines" for design Strength, really just spline series but with everything hidden to make them appear in the legend
  // Filter out non-co2 mixes
  let mixDesignSplineAsPlotLinesDesignStrength: Array<SeriesSplineOptions> = co2MixVariations.map(
    mixVariation =>
      getHorizontalLineSeriesHighCharts(
        mixVariation.designStrength ?? 0,
        `${mixVariation.variationIdLabel} - Design Strength`,
        '#72b99d',
        `${mixVariation.variationIdLabel}_Des_Str`,
        'Dash'
      )
  )

  // bar graph series only for control mixes
  let controlMixColumnSeries: SeriesColumnOptions[] = noCO2MixVariations.map(
    mixVariation =>
      ({
        name: mixVariation.variationIdLabel,
        // replace first value for 7 day strength
        data: [
          meanSevenDayStrength[
            mixVariation.variationIdLabel + '-' + mixVariation.condition
          ],
          avgTwentyEightDayStrength[
            mixVariation.variationIdLabel + '-' + mixVariation.condition
          ],
        ],
        dataLabels: {
          enabled: true,
        },
        type: 'column',
        color: 'rgb(75, 83, 87, 0.65)',
        id: String(mixVariation.variationIdLabel),
      } as SeriesColumnOptions)
  )

  let co2MixColumnSeries: SeriesColumnOptions[] = co2MixVariations.map(
    mixVariation =>
      ({
        type: 'column',
        name: mixVariation.variationIdLabel,
        dataLabels: { enabled: true },
        data: [
          {
            y:
              meanSevenDayStrength[
                mixVariation.variationIdLabel + '-' + mixVariation.condition
              ],
            id: `${mixVariation.variationIdLabel}_7_day`,
          },
          {
            y:
              avgTwentyEightDayStrength[
                mixVariation.variationIdLabel + '-' + mixVariation.condition
              ],
            id: `${mixVariation.variationIdLabel}_28_day`,
          },
        ],
        color: 'rgb(227, 127, 28, 0.35)',
        id: String(mixVariation.variationIdLabel),
      } as SeriesColumnOptions)
  )

  /** Annotations to present the % over the design strength */
  let co2Annotations: AnnotationsOptions[] = co2MixVariations.map(
    mixVariation =>
      createCo2BarGraphAnnotationOption(
        mixVariation,
        meanSevenDayStrength,
        avgTwentyEightDayStrength
      )
  )

  const chartOptions: Options = {
    chart: {
      type: 'column',
      plotBackgroundImage:
        graphWatermark === warningUnder30 ? undefined : graphWatermark,
    },
    title: {
      text: 'Average Compressive Strength',
      align: 'center',
    },
    yAxis: {
      title: {
        text: `Strength (${strengthUnit})`,
      },
      min: 0,
    },
    xAxis: [
      {
        categories: ['7 Days', '28 Days'],
        title: {
          text: 'Maturity Age',
        },
        id: 'interval_categories',
      },
      {
        id: 'line_axis',
        visible: false,
      },
    ],
    series: [
      ...controlMixColumnSeries,
      ...co2MixColumnSeries,
      ...mixDesignSplineAsPlotLinesDesignStrength,
    ],
    annotations: co2Annotations,
  }

  return chartOptions
}

/**
 * Filters mix variations based on the orcaVariationType property.
 *
 * @param {ICommissionReportVariation[]} mixVariations The mix selection array.
 * @param {string} variationType can be "Optimized" or "Baseline"
 * @returns An array containing only the variations matching the specified variation type.
 */
export function findVariationByType(
  mixVariations: ICommissionReportVariation[],
  variationType: string
) {
  return mixVariations?.find(
    variation => variation.orcaVariationType === variationType
  )
}

export function getStrength(
  mixVariation: ICommissionReportVariation | undefined,
  interval: number,
  unit: Units
) {
  /*
  Setting the strength to undefined will render a null value
  in the Optimized series for the chart.
  Since there is no value expected, it is safe to assume that this is zero 
  */
  const strength = mixVariation?.strengths?.[interval]?.strength ?? 0

  const decimalPlaces = unit === Units.Metric ? 2 : 0
  return typeof strength === 'number'
    ? Number(strength.toFixed(decimalPlaces))
    : strength
}

/**
 * Function to get the bar graph options. Adds in the horizontal lines for design strength
 * (excludes required strength while the mixGrouping functionality isn't available).
 *
 * @param mixSelection Array of selected mix variations
 * @param unit Unit as defined by the enum. Currently only imperial is considered as an option but this unit is used for future options
 * @returns HighChart Options for a bar graph, with lines representing design strength for CO2 Mixes
 */
export function getCommissionReportBarGraphOptions(
  selectedMixSelection: IMixSelection,
  selectedMixVariations: ICommissionReportVariation[] | null,
  showDesignStrength: boolean,
  selectedInterval: number[],
  unit: Units = Units.Imperial
): Options {
  let strengthUnit = getStrengthUnit(unit)
  let selectedDesignStrength: number | null = null
  let specifiedMaturityAge: number | null = null

  if (selectedMixSelection?.designStrength) {
    const propertyValue = getPropertyValue(
      selectedMixSelection,
      'designStrength',
      unit === Units.Metric
    )
    selectedDesignStrength = parseFloat(propertyValue as string)
    specifiedMaturityAge = selectedMixSelection.specifiedMaturityAge
      ? selectedMixSelection.specifiedMaturityAge / 24
      : null // Convert hours to days
  }

  const optimizedVariation = findVariationByType(
    selectedMixVariations as ICommissionReportVariation[],
    VariationTypes.OPTIMIZED
  )
  const baselineVariation = findVariationByType(
    selectedMixVariations as ICommissionReportVariation[],
    VariationTypes.BASELINE
  )

  const optimizedDataValues = selectedInterval.map(interval =>
    getStrength(optimizedVariation, interval, unit)
  )
  const baselineDataValues = selectedInterval.map(interval =>
    getStrength(baselineVariation, interval, unit)
  )

  const sortedDataObject = sortGraphDataAndLabels(
    optimizedDataValues,
    baselineDataValues,
    selectedInterval
  )

  const baselineIndices = sortedDataObject.sortedBaselineData
    .map((value, index) => (value !== null ? index : -1))
    .filter(index => index !== -1)
  const optimizedIndices = sortedDataObject.sortedOptimizedData
    .map((value, index) => (value !== null ? index : -1))
    .filter(index => index !== -1)
  const uniqueIndices = Array.from(
    new Set([...baselineIndices, ...optimizedIndices])
  )

  // Convert interval labels to days for comparison
  const intervalLabelsInDays = sortedDataObject.sortedIntervalLabels.map(
    label => {
      const match = label.match(/^(\d+)\s*Days?$/i)
      return match ? parseInt(match[1], 10) : null
    }
  )

  // Find index for the specified maturity age
  const designStrengthPlotLinesIndex = uniqueIndices.findIndex(
    index => intervalLabelsInDays[index] === specifiedMaturityAge
  )

  const designStrengthPlotLines = []
  if (designStrengthPlotLinesIndex !== -1) {
    designStrengthPlotLines.push({
      type: 'spline',
      data: [
        { y: selectedDesignStrength, x: designStrengthPlotLinesIndex - 0.3 },
        { y: selectedDesignStrength, x: designStrengthPlotLinesIndex + 0.3 },
      ],
      showInLegend: false,
      name: 'Design Strength',
      xAxis: 'interval_categories',
      lineWidth: 1,
      visible: showDesignStrength,
      color: '#000000',
      dashStyle: 'Dash',
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    })
  }

  const optimizedSeries: SeriesColumnOptions[] = [
    {
      name: VariationTypes.OPTIMIZEDALT,
      showInLegend: false,
      data: sortedDataObject.sortedOptimizedData,
      dataLabels: {
        enabled: true,
        formatter: function() {
          const dataIndex = this.point.index
          const optimizedY = sortedDataObject.sortedOptimizedData[dataIndex]
          const baselineY = sortedDataObject.sortedBaselineData[dataIndex]
          const relativePercentage =
            optimizedY && baselineY
              ? Math.round((optimizedY / baselineY) * 100) + '%'
              : ''
          return relativePercentage
        },
      },
      type: 'column',
      color: 'rgba(227, 127, 28, 1)',
      id: String(optimizedVariation?.orcaVariationType),
    },
  ]

  const baselineSeries: SeriesColumnOptions[] = [
    {
      name: VariationTypes.BASELINE,
      showInLegend: false,
      data: sortedDataObject.sortedBaselineData,
      dataLabels: {
        enabled: false,
      },
      type: 'column',
      color: 'rgba(26, 67, 115, 1)',
      id: String(baselineVariation?.orcaVariationType),
    },
  ]

  const chartOptions: Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Average Strength',
      align: 'center',
      x: 40,
    },
    yAxis: {
      title: {
        text: `Batch Strength (${strengthUnit})`,
        margin: 16,
      },
      min: 0,
      labels: {
        formatter: function() {
          return String(this.value)
        },
      },
    },
    xAxis: [
      {
        categories: sortedDataObject.sortedIntervalLabels,
        title: {
          text: 'Maturity Age',
          margin: 12,
        },
        labels: {
          y: 24,
        },
        id: 'interval_categories',
      },
    ],
    credits: {
      enabled: false,
    },
    series: [...baselineSeries, ...optimizedSeries, ...designStrengthPlotLines],
  }

  return chartOptions
}

/**
 * Prepares data for graph, sets config for Highcharts, collects list of data error messages.
 * Exported here for reuse in Storybook.
 * @param {Array<MixGroupVariation>} selectedMixVariations array of mix variations to appear in the graph.
 * @param {String} selectedProperty currently selected property used to color graph points.
 * @param {Function} addToBatchDataTable collects selected graph points and adds them to a table component.
 * @param {Boolean} isMetric whether or not the measurement system is metric
 * @returns {[Options, Array]} A graph options object including data, and an array of messages.
 */
export const getVariabilityGraphOptionsAndErrors = (
  selectedMixVariations: Array<MixGroupVariation>,
  selectedProperty: FreshPropertyNumberKeys,
  addToBatchDataTable: Function,
  isMetric: boolean
): [Options, Array<string>] => {
  let noSampleData: Array<string> = []
  //The required data is an array of tuples of [IndexOfIterator, batchStrength, <FreshProperty>, and batchId]
  let selectedDataAsTuple: Array<[
    number,
    number | null,
    number,
    number,
    string | null
  ]> = []
  interface FreshPropertyData {
    x: number
    value: number
    y: number
    color: string
    batchId: number
  }
  let highChartsSeries: Array<SeriesScatterOptions> = []
  let selectedPropertyValues: Array<number> = []

  let freshPropertiesData: Array<FreshPropertyData> = []

  let highChartsColorAxes: Array<ColorAxisOptions> = []
  // when/how is this used
  // probably can just remove? Idk we'll see
  let plotlines: Array<YAxisPlotLinesOptions> = []
  let plotlineColorList = ['#59bb98', '#0c1921']
  let strengths: Array<number> = []
  selectedMixVariations.forEach((selected, index) => {
    freshPropertiesData = []
    selectedDataAsTuple = []
    selectedPropertyValues = []
    let testNo = 1

    // select the appropriate color by condition
    let colorSetBasedOnCondition =
      colorAxisColors[selected.condition]?.[index % 2]

    // only consider non null strengths
    const supportedFreshProperties = selected.freshProperties.filter(
      freshProperty => freshProperty.batchStrength !== null
    )
    //A supported fresh property is one that has a non-null batchStrength
    //and a batchInterval of 28 days
    supportedFreshProperties.forEach(supportedFreshProperty => {
      selectedDataAsTuple.push([
        testNo,
        supportedFreshProperty.batchStrength,
        supportedFreshProperty[selectedProperty]
          ? //if the supportedFreshProperty isn't falsey it's a number
            (supportedFreshProperty[selectedProperty] as number)
          : 0,
        supportedFreshProperty['batchId'],
        supportedFreshProperty['co2DosageUnit'],
      ])
      testNo += 1
      strengths.push(supportedFreshProperty.batchStrength ?? 0) //probably not the best way to get around possible nulls
    })

    // Move to next mix variation
    if (!selectedDataAsTuple.length) {
      noSampleData.push(
        selected.variationIdLabel + ' does not have 28 day samples'
      )
      return
    }

    // divide the data by the property selected
    selectedDataAsTuple.forEach(([, , selectedPropertyValue]) => {
      selectedPropertyValues.push(selectedPropertyValue)
    })

    selectedPropertyValues = selectedPropertyValues.sort((a, b) => a - b)
    const maxRanges = getMaxRanges(selectedPropertyValues)

    // Can replace with a map and return
    selectedDataAsTuple.forEach(row => {
      // the colorSetBasedOnCondition will always have 4 items
      const color = getMixColor(
        row,
        maxRanges,
        colorSetBasedOnCondition as [string, string, string, string]
      )
      freshPropertiesData.push({
        x: row[0],
        value: roundUpToDecimal(
          row[2],
          getBatchSampleUnitPrecision(selectedProperty, isMetric, row[4])
        ),
        y: Number(row[1]),
        color: color,
        batchId: row[3],
      })
    })

    highChartsSeries.push({
      showInLegend: true,
      name: selected.variationIdLabel,
      data: freshPropertiesData,
      color: colorSetBasedOnCondition[1],
      marker: {
        radius: 4.5,
        symbol: dataPointsSymbols[index % dataPointsSymbols.length],
      },
      type: 'scatter',
    })

    highChartsColorAxes.push({
      //issue has been isolated to this ID key
      //When the id is nonesense, works fine? - probably because it's not being applied to anything
      //When the id is undefined, also causes crash
      id: selected.variationIdLabel,
      min: 0,
      max: 10,
      minColor: '#FFFFFF',
      maxColor: '#000000',
      //marked as an error, shouldn't be though as per the docs. The type should be PointMarkerOptionsObject | null
      //This is the workaround for now, should probably make an MR/open an issue to highcharts concerning this
      marker: (null as unknown) as PointMarkerOptionsObject,
      showInLegend: true,
    })

    if (selectedMixVariations.length === 1) {
      plotlines.push({
        color: plotlineColorList[0],
        value: Number(selected.requiredStrength),
        label: {
          text: selected.variationIdLabel + ' - Required Strength',
          align: 'right',
          textAlign: 'right',
          style: {
            fontWeight: 'bold',
          },
        },
        width: 3,
      })
      plotlines.push({
        color: plotlineColorList[1],
        value: Number(selected.averageStrength),
        label: {
          text: selected.variationIdLabel + ' - Average Strength',
          align: 'right',
          textAlign: 'right',
          style: {
            fontWeight: 'bold',
          },
        },
        width: 3,
      })
    } else {
      plotlines = []
    }
  })
  const yMaxConstant = isMetric ? 3 : 500
  const graphOptions: Options = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      plotBackgroundImage: getGraphWatermark(selectedMixVariations),
    },
    title: {
      text: undefined,
    },
    xAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit.samples
          : axisLabelsWithImperialUnit.samples,
      },
      tickInterval: 1,
    },
    yAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit.batchStrength
          : axisLabelsWithImperialUnit.batchStrength,
      },
      plotLines: plotlines,
      max: strengths.length > 0 ? Math.max(...strengths) + yMaxConstant : null,
      min: 0,
    },
    plotOptions: {
      scatter: {
        point: {
          events: {
            click: function() {
              addToBatchDataTable(
                (this as FreshPropertyData & Point).batchId,
                this.y
              )
            },
          },
        },
      },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: isMetric
        ? `<b>{series.name} <br/> Sample #: </b>{point.x}<br/><b> 
        ${axisLabelsWithMetricUnit['batchStrength']} 
        : </b> {point.y${getTooltipBatchSampleUnitPrecision(
          'batchStrength',
          true
        )}}<br/><b>
        ${axisLabelsWithMetricUnit[selectedProperty]}
        : </b>{point.value}`
        : `<b>{series.name} <br/> Sample #: </b>{point.x}<br/><b> 
        ${axisLabelsWithImperialUnit['batchStrength']} 
        : </b> {point.y${getTooltipBatchSampleUnitPrecision(
          'batchStrength',
          false
        )}}<br/><b>
        ${axisLabelsWithImperialUnit[selectedProperty]}
        : </b>{point.value}`,
    },
    legend: {
      verticalAlign: 'bottom',
      align: 'left',
    },
    //colorAxis removed - There exists an error concerning the highChartsColorAxes
    //When removing a selected mix design, if there are colorAxes the app crashes
    series: highChartsSeries,
  }
  return [graphOptions, noSampleData]
}

/**
 * Filters Analysis page graphs by test category.
 * @param {Object} graphFilter filter currently being applied.
 * @param {String | Number} batchProperty property with which to filter the graphs.
 * @returns {Boolean} Whether or not the graphs are to be filtered by test category
 */
export const testCategoryFilter = (
  graphFilter: GraphFilter,
  batchProperty: string | number
) => {
  if (batchProperty === 'CommissioningTrial') {
    return commissioningTrialValues.includes(batchProperty)
  } else return graphFilter.selectedOptions.includes(batchProperty)
}

/**
 * Function to get thresholds for the graph type
 *
 * @param {'Histogram' | 'Variability' | 'Sandbox' | 'Strength Development' | 'Bar'} graphType The graph type from the option select
 * @returns {{error: number, warning: number}} returns an object with the error and warning threshold
 */
export function getGraphingThresholds(
  graphType:
    | 'Histogram'
    | 'Variability'
    | 'Sandbox'
    | 'Strength Development'
    | 'Bar'
) {
  //The bar graph has special consideration
  if (graphType === 'Bar') {
    return {
      error: -1,
      warning: 15,
    }
  }

  //We return these thresholds since if it is not a bar graph we want the standard
  return {
    error: 15,
    warning: 30,
  }
}

/**
 * function mixDesignsToExistingMixDesignsRows
 * @param {Array} mixDesignArray
 * @returns {Object} simpleTable row format
 */
export function mixDesignsToExistingMixDesignsRows(
  arr: Array<DigestedMixDesign>
) {
  return arr.map(r => {
    r.variations.forEach((variation: MixGroupVariation) => {
      // for each variation, add cells for the table
      variation.cells = [
        null,
        null,
        variation.variationIdLabel,
        null,
        null,
        variation.cementReductionPercent,
        roundUpToDecimal(
          variation.cO2DosageLabel,
          getCO2DosagePrecision(variation.cO2DosageUnit, false)
        ),
        variation.cO2DosageUnitLabel,
        variation.sampleCount,
        variation.cylinderCount,
        null,
        null,
        r.waterCementRatio?.toFixed(3),
        r.cementAmountTotal?.toFixed(0),
        r.totalYield?.toFixed(2),
        '',
      ]
    })
    return {
      id: r.mixDesignId,
      cells: [
        r.producer,
        r.locationName,
        r.mixCode,
        {
          behavior: CellBehavior.Chips,
          content: [
            {
              text: r.status,
              style:
                r.status === MixGroupStatus.ACTIVE ? activeTag : archivedTag,
            },
          ],
        },
        {
          behavior: CellBehavior.Chips,
          content: [
            {
              text:
                r.condition === MixGroupConditions.CO2
                  ? MixGroupConditions.CO2
                  : MixGroupConditions.NOCO2,
              style: r.condition === MixGroupConditions.CO2 ? co2Tag : noCO2Tag,
            },
          ],
        },
        r.cementReductionPercent,
        r.cO2Dosage,
        r.cO2DosageUnit ? getCO2DosageUnitLabel(r.cO2DosageUnit) : '%',
        r.totalBatches,
        r.totalCylinders,
        r.designStrength?.toFixed(0),
        r.interval,
        r.waterCementRatio?.toFixed(3),
        r.cementAmountTotal?.toFixed(0),
        r.totalYield?.toFixed(2),
        {
          behavior: CellBehavior.ButtonLink,
          content: ['editAssociation'],
        },
      ],
      variations: r.variations,
    }
  })
}

/**
 * function mixDesignsToMixDesignsRows
 * @param {Array} mixDesignArray
 * @param {boolean} isMetric whether or not the measurement system is metric
 * @returns {Object} simpleTable row format
 */
export function mixDesignsToMixDesignsRows(arr, isMetric: boolean) {
  return arr.map(r => {
    const { status, condition } = getStatusAndConditionTags(r)
    if (!r.variationId) {
      r.variations.forEach((variation: MixGroupVariation) => {
        // for each variation, add cells for the table
        variation.cells = [
          null,
          null,
          variation.variationIdLabel,
          null,
          null,
          variation.cementReductionPercent,
          roundUpToDecimal(
            variation.cO2DosageLabel,
            getCO2DosagePrecision(variation.cO2DosageUnit, false)
          ),
          variation.cO2DosageUnitLabel,
          variation.sampleCount,
          null,
          isMetric
            ? variation.averageStrength?.toFixed(2)
            : variation.averageStrength?.toFixed(0),
          r.cementAmountTotal?.toFixed(0),
          variation.cementitiousEfficiency?.toFixed(2),
          r.waterCementRatio?.toFixed(3),
          r.percentScm?.toFixed(2),
          r.totalYield?.toFixed(2),
        ]
      })
    }
    return {
      id: r.mixDesignId,
      cells: [
        r.producer,
        r.locationName,
        r.mixCode,
        status,
        condition,
        r.cementReductionPercent,
        r.cO2Dosage,
        getCO2DosageUnitLabel(r.cO2DosageUnit),
        r.totalBatches,
        isMetric ? r.designStrength?.toFixed(2) : r.designStrength?.toFixed(0),
        null,
        r.cementAmountTotal?.toFixed(0),
        r.cementitiousEfficiency?.toFixed(2),
        r.waterCementRatio?.toFixed(3),
        r.percentScm?.toFixed(2),
        r.totalYield?.toFixed(2),
      ],
      variations: r.variations,
    }
  })
}

/**
 * function mixVariationsToMixVariationsRows
 * @param {Array} mixVariationArray
 * @param {boolean} isMetric whether or not the measurement system is metric
 * @param {ISimpleTableSettings} tableSettings settings for the table
 * @returns {Object} simpleTable row format
 */
export function mixVariationsToMixVariationsRows(
  arr,
  isMetric: boolean,
  tableSettings: ISimpleTableSettings
) {
  const { rowsPerPage, page } = tableSettings
  return getCurrentPageRows(arr, page, rowsPerPage).map(r => {
    const { status, condition } = getStatusAndConditionTags(r)
    return {
      id: r.variationId,
      cells: [
        r.producer,
        r.locationName,
        r.variationIdLabel,
        status,
        condition,
        r.cementReductionPercent,
        roundUpToDecimal(
          r.cO2DosageLabel,
          getCO2DosagePrecision(r.cO2DosageUnit, false)
        ),
        r.co2DosageUnitLabel,
        r.sampleCount,
        isMetric ? r.designStrength?.toFixed(2) : r.designStrength?.toFixed(0),
        isMetric
          ? r.averageStrength?.toFixed(2)
          : r.averageStrength?.toFixed(0),
        r.cementAmountTotal?.toFixed(0),
        r.cementitiousEfficiency?.toFixed(2),
        r.waterCementRatio,
        r.percentScm?.toFixed(2),
        r.totalYield?.toFixed(2),
      ],
    }
  })
}

/**
 * function preserveWatermarkAspectRatio
 * @param {Object|null} node a reference node. attached to highcharts chart
 * @param {Element|null} watermarkedImage the warning background image on highcharts chart
 * @returns {null|undefined}
 */
export function preserveWatermarkAspectRatio(
  node: object | null,
  watermarkedImage: Element | null
) {
  if (!node || !watermarkedImage) {
    return null
  }
  watermarkedImage.setAttribute('preserveAspectRatio', 'xMidYMid')
}

/**
 * function tssCanWrite
 * @param {any} roles an array of strings. the role of the logged in user.
 * @returns {boolean} whether this role has WRITE access
 */
export function tssCanWrite(roles: any) {
  return roles.includes('TSS_W') || roles.includes('OrcaAdmin')
}

/**
 * Checks if the user has the 'TSS' role for read access, excluding write and review roles.
 * @param {string[]} roles - The roles of the logged-in user.
 * @returns {boolean} - Whether the user has read access without write or review permissions.
 */
export function tssCanRead(roles: string[]): boolean {
  const allowedRoles = ['TSS']
  const restrictedRoles = ['TSS_W', 'TSS_Review']

  const hasAllowedRole = allowedRoles.some(role => roles.includes(role))
  const hasRestrictedRole = restrictedRoles.some(role => roles.includes(role))

  return hasAllowedRole && !hasRestrictedRole
}

/**
 * This is not the same as TSS read only. This restricts access to all TSS pages except for the Report Library
 * @param {any} roles an array of strings. the role of the logged in user.
 * @returns {boolean} whether this role has Orca read-only access.
 */
export function orcaReadOnly(roles: any) {
  // In some cases (testing), we may be given roles that both restrict access, and grant access to TSS pages.
  // The conditions below take this into account so that TSS access roles are given the priortity when combined with Orca read only roles.
  const checkedRoles =
    roles.includes('Orca') &&
    !roles.includes('TSS_W') &&
    !roles.includes('TSS') &&
    !roles.includes('TSS_Review') &&
    !roles.includes('OrcaAdmin')
  return checkedRoles
}

/**
 * function tssCanReview
 * @param {any} roles an array of strings. the role of the logged in user.
 * @returns {boolean} whether this role has WRITE and REVIEW access
 */
export function tssCanReview(roles: any) {
  return roles.includes('TSS_Review') || roles.includes('OrcaAdmin')
}

/**
 * function getEditOrViewBtnLabel
 * @param {any} roles an array of strings. the role of the logged in user.
 * @param {string} dataCategory the type of data being viewed/modified (eg mix design, batch actuals)
 * @returns {string} a string which will be shown on the button
 */
export function getEditOrViewBtnLabel(roles: any, dataCategory: string) {
  if (dataCategory === 'mixDesign') {
    return 'View Mix Design'
  } else if (dataCategory === 'batchActuals') {
    return 'View Batch Data'
  }
}

/**
 * function setHeadersText
 * @param {boolean} editMode whether data is being edited or added
 * @param {any} roles an array of strings. the role of the logged in user.
 * @returns {object} an object containing the headings
 */
export function setHeadersText(
  hasMixDesignId: boolean,
  roles: any,
  isEditMode: boolean
) {
  if (hasMixDesignId && isEditMode) {
    return {
      h1: 'Edit Mix',
      h2: '1. Enter Design Details',
      h3: '2. Edit Materials',
    }
  } else if (hasMixDesignId && !isEditMode) {
    return {
      h1: 'View Mix',
      h2: 'Design Details',
      h3: 'Materials',
    }
  } else {
    return {
      h1: 'Add Mix',
      h2: '1. Enter Design Details',
      h3: '2. Add Materials',
    }
  }
}
/**
 *
 * @param {string} locationText
 * @param {string} customerText
 * @param {string} mixCode
 * @param {any} roles array of strings. the role of the logged in user
 * @returns
 */
export const getAlertText = (
  customerText: string,
  mixCode: string | undefined,
  roles: any,
  isEditMode: boolean
) => {
  const mixDesign = `${customerText} - Mix Group ${mixCode}`

  if (mixCode) {
    if (isEditMode) {
      return 'You cannot add new samples (rows) while editing, you can only edit existing data or add fields to existing samples.'
    } else {
      return [
        'You are currently viewing preexisting batching data for ',
        mixDesign,
      ]
    }
  } else {
    if (isEditMode) {
      return 'You cannot add new samples (rows) while editing, you can only edit existing data or add fields to existing samples.'
    } else {
      return [
        'You are currently viewing preexisting batching data for ',
        customerText,
      ]
    }
  }
}

export const getCommissionAlertText = (
  mixCode: string | undefined,
  location: string,
  producerName: string,
  roles: string[],
  isCommissionReportViewMode: boolean
) => {
  if (isCommissionReportViewMode) {
    const switchToEditModeMessage = tssCanWrite(roles)
      ? 'To make changes to the report, click on "Switch To Edit Mode".'
      : ''
    return `You are currently viewing commissioning report for ${producerName} - ${location}, ${mixCode}. ${switchToEditModeMessage}`
  } else {
    return `You are currently viewing commissioning report for ${producerName} - ${location}, ${mixCode}. To view the report without making any changes, click on "Switch To View Mode".`
  }
}

export const getCommissionAlertHeader = (
  isCommissionReportViewMode: boolean
) => {
  if (isCommissionReportViewMode) {
    return 'You Are in View Mode'
  } else {
    return 'You Are in Edit Mode'
  }
}

export const getAlertHeader = (isEditMode: boolean) => {
  if (isEditMode) {
    return 'You are in edit mode'
  } else {
    return 'You are in view mode'
  }
}

/**
 *
 * @param {Date} date
 * @param {string} operation
 * @param {string} num
 * @returns
 */
export const addOrSubtractDays = (
  date: Date,
  operation: string,
  num: number
) => {
  if (operation === 'add') {
    date.setDate(date.getDate() + num)
  } else {
    date.setDate(date.getDate() - num)
  }
}
/**
 *
 * @param {string} type category of the options (eg customer, location)
 * @param {any} options Customer Name options or Location name options
 * @param {boolean} inputAlwaysEnabled enable inputs regardless of role
 * @param {any} roles array of strings. the role of the logged in user
 * @returns {boolean}
 */
export const disableCustomerAndLocationSelect = (
  type: string,
  options: Array<PlantOptions> | Array<CustomerOptions>,
  inputsAlwaysEnabled: boolean,
  roles: string,
  isAssociationView?: boolean,
  inAssociation?: boolean,
  isViewOnly?: boolean,
  isEditMode?: boolean
) => {
  if (
    isAssociationView ||
    inAssociation ||
    isViewOnly ||
    (type === 'customer' && isEditMode)
  ) {
    return true
  }
  if (type === 'customer') {
    return !(options && (inputsAlwaysEnabled || tssCanWrite(roles)))
  }
  return !(options?.length > 0 && (inputsAlwaysEnabled || tssCanWrite(roles)))
}

/**
 *
 * @param {array} roles
 * @param {string} dataCategory
 * @param {string} mixDesignId
 * @returns {boolean} a boolean based on role permissions
 */
export const disableSubmitButton = (
  isEditMode: boolean,
  dataCategory: string,
  search: string | undefined = ''
) => {
  const params = new URLSearchParams(search)
  if (dataCategory === 'mixDesign') {
    const mixDesignId = params.get('mixDesignId')
    if (!mixDesignId) return false
  } else if (dataCategory === 'batchActuals') {
    const batchTestSampleIds = params.get('batchTestSampleIds')
    if (!batchTestSampleIds) return false
  }
  return !isEditMode
}

/**
 *
 * @param {array} batches array of batches for a variation
 * @returns {MixGroupVariation} an object containing batch information
 */

export const getBatchInformationForVariation = (
  batches: MixVariationBatchList,
  variation: MixGroupVariation
) => {
  let freshProperties: Array<FreshPropertyObject> = []
  const uniqueProductionDates = new Set<string>()
  const isMetric = variation.isMetric
  batches.forEach(sample => {
    /* Arrange fresh properties by batch sample */
    sample?.strengthReadings?.forEach(strengthReading => {
      uniqueProductionDates.add(sample.productionDate)
      freshProperties.push({
        batchId: sample.batchSampleId,
        air: sample.airContent,
        slump: isMetric
          ? sample.slumpMm
          : ConvertMMtoINCHUnrounded(sample.slumpMm),
        concreteTemperature: isMetric
          ? sample.concreteTemperature
          : ConvertCtoF(sample.concreteTemperature),
        ambientTemperature: isMetric
          ? sample.ambientTemperature
          : ConvertCtoF(sample.ambientTemperature),
        batchWaterCementRatio: sample.batchWaterCementRatio,
        batchCementContent: isMetric
          ? sample.cementContent
          : convertKgM3ToLbYd3(sample.cementContent),
        co2Dosage: variation.cO2Dosage,
        co2DosageLabel: variation.cO2DosageLabel,
        co2DosageUnit: variation.cO2DosageUnit,
        co2DosageUnitLabel: getCO2DosageUnitLabel(
          variation.cO2DosageUnit,
          isMetric
        ),
        batchStrength: isMetric
          ? strengthReading.batchStrength
          : ConvertMPAToPSIUnrounded(strengthReading.batchStrength),
        batchInterval: strengthReading.batchInterval,
        productionDate: sample.productionDate,
        batchNotes: sample.notes,
        externalTicketId: sample.externalTicketId,
        testCategory: sample.testCategory,
        isMetric: isMetric,
      })
    })
  })
  return { freshProperties, uniqueProductionDates }
}

/**
 *
 * @param {string} cO2DosageUnit the unit for the CO2Dosage
 * @param {boolean} isMetric whether or not we're using the metric system
 * @returns {string} label for cO2DosageUnit
 */
export const getCO2DosageUnitLabel = (
  cO2DosageUnit: CO2DosageUnit,
  isMetric = true,
  showPercentBWC?: boolean
) => {
  switch (cO2DosageUnit) {
    case 'PercentOfCement':
      return showPercentBWC ? '% bwc' : '%'
    case 'LitrePerM3':
      return isMetric ? PropertyUnit.MilliLitrePerM3 : PropertyUnit.OzPerYd3
    default:
      return ''
  }
}

/**
 *
 * @param {string} mixCode the variation mixcode in the form mixCode-cementReductionPercent-cO2DosagecO2DosageUnit
 * @param {string} co2DosageUnitLabel label for the unit. ie unit PercentOfCement has label %
 * @returns {string} label for variationId in the form mixDesignId-cementReductionPercent-cO2DosagecO2DosageUnitLabel
 */
export const getVariationMixCodeLabel = (
  id: string,
  co2DosageUnitLabel: string
) => {
  return id.replace(co2DosageUnitLabel, '')
}

/**
 *
 * @param {any} r mix variation or mix group
 * @returns {object} object containing 2 key/value pairs needed to render the status and condition chips in the mix group/mix variation tables
 */
const getStatusAndConditionTags = (
  r: DigestedMixDesign | MixGroupVariation
) => {
  return {
    status: {
      behavior: CellBehavior.Chips,
      content: [
        {
          text: r.status,
          style: r.status === MixGroupStatus.ARCHIVED ? archivedTag : activeTag,
        },
      ],
    },
    condition: {
      behavior: CellBehavior.Chips,
      content: [
        {
          text: r.condition,
          style: r.condition === MixGroupConditions.CO2 ? co2Tag : noCO2Tag,
        },
      ],
    },
  }
}

/**
 * Function to get the common ids between mix variations and selected mix variations
 * @param {Array} variations of the mix design
 * @returns {Array} selectedMixVariations
 */
export const getCommonVariationIds = (
  variations: Array<MixGroupVariation>,
  selectedMixVariations: Array<MixGroupVariation>
) => {
  const variationIds = []
  const selectedVariationIds: Array<string> = []
  for (const variation of variations) {
    variationIds.push(variation.variationId)
  }
  for (const selectedMixVariation of selectedMixVariations) {
    selectedVariationIds.push(selectedMixVariation.variationId)
  }
  return variationIds.filter(e => selectedVariationIds.indexOf(e) > -1)
}

/**
 *
 * @param {Array} variations of the mix design
 * @returns {Array} selectedMixVariations
 */
export const getVariationProperties = (variation: MixGroupVariation) => {
  const obj: {
    CO2Dosage?: number
    CO2DosageUnit?: string
    cementReduction?: number
  } = {}
  if (variation.cO2Dosage || variation.cO2Dosage === 0)
    obj.CO2Dosage = variation.cO2Dosage
  if (
    variation.cementReductionPercent ||
    variation.cementReductionPercent === 0
  )
    obj.cementReduction = variation.cementReductionPercent
  if (variation.cO2DosageUnit) obj.CO2DosageUnit = variation.cO2DosageUnit
  return obj
}
/**
 * Generic Functions to reduce duplicates
 * @param {Array} variations an array of variations
 * @param {Array} mixDesigns an array of mix designs
 * @returns {Array} variations or mix designs
 * @returns {object} when findById
 */
export const filterVariationsById = (
  variations: Array<MixGroupVariation> | Array<DigestedBatchActual>,
  id: number | string
) => {
  return variations.filter(
    (variation: MixGroupVariation | DigestedBatchActual) =>
      variation.variationId !== id
  )
}

export const filterMixDesignsById = (
  mixDesigns: Array<DigestedMixDesign>,
  id: number | string
) => {
  return mixDesigns.filter(design => design.mixDesignId !== id)
}

export const filterBatchActualsById = (
  batchActuals: Array<DigestedBatchActual>,
  id: number | string
) => {
  return batchActuals.filter(batch => batch.mixDesignId !== id)
}

export const findVariationById = (
  variations: Array<MixGroupVariation>,
  id: number | string | undefined
) => {
  return variations.find(
    variation => variation.variationId === id || variation.mixCode === id
  )
}

export const findMixDesignById = (
  mixDesigns: Array<DigestedMixDesign>,
  id: number | string
) => {
  return mixDesigns.find(design => design.mixDesignId === id)
}

/**
 * function materialsToMaterialTableRows
 * @param {Array} material
 * @returns {Object} simpleTable row format
 */
export function materialsToMaterialTableRows(arr: Array<IMaterial>) {
  return arr.map(r => {
    const materialSubtype = Object.entries(MaterialSubTypeOptions).find(
      ([key, value]) => key === r.materialType
    )
    const materialType = Object.entries(MaterialTypeOptions).find(
      ([key, value]) => key === r.primaryMaterialType
    )
    const defaultCementFlag = isDefaultCement(r)
    const defaultCO2Flag = isDefaultCO2(r)

    return {
      id: r.materialMappingId,
      cells: [
        r.divisionName,
        materialType?.[1], //use the value of the enum property
        materialSubtype?.[1], //use the value of the enum property
        r.primaryMaterialType === MaterialTypeOptions.Cement
          ? r.cementSupplier?.plantName
          : r.supplierCompany,
        r.supplierPlant,
        r.aliases,
        {
          behavior: CellBehavior.ButtonLink,
          content: [
            'mergeMaterial',
            r.isIngested,
            defaultCementFlag,
            defaultCO2Flag,
          ],
        },
      ],
    }
  })
}

export const convertMixDesignUnit = (
  isMetric: boolean,
  mixDesign: DigestedMixDesign
) => {
  if (isMetric && isMetric !== mixDesign.isMetric) {
    mixDesign.designStrength = ConvertPSIToMPAUnrounded(
      mixDesign.designStrength
    )
    mixDesign.requiredStrength = ConvertPSIToMPAUnrounded(
      mixDesign.requiredStrength
    )
    mixDesign.averageStrength = ConvertPSIToMPAUnrounded(
      mixDesign.averageStrength
    )
    mixDesign.standardDeviation = ConvertPSIToMPAUnrounded(
      mixDesign.standardDeviation
    )
    mixDesign.cementitiousEfficiency = ConvertPSILbToMPAKgUnrounded(
      mixDesign.cementitiousEfficiency
    )
    mixDesign.cementAmountTotal = ConvertLbPerYd3ToKgPerM3Unrounded(
      mixDesign.cementAmountTotal
    )
    const variations = mixDesign.variations.map(
      (variation: MixGroupVariation) => {
        variation.designStrength = mixDesign.designStrength
        variation.averageStrength = ConvertPSIToMPAUnrounded(
          variation.averageStrength
        )
        variation.requiredStrength = ConvertPSIToMPAUnrounded(
          variation.requiredStrength
        )
        variation.cementitiousEfficiency = ConvertPSILbToMPAKgUnrounded(
          variation.cementitiousEfficiency
        )
        variation.cementAmountTotal = mixDesign.cementAmountTotal
        variation.isMetric = true
        variation.cO2DosageLabel = getCO2Dosage(
          variation.cO2Dosage,
          variation.cO2DosageUnit,
          isMetric
        ) // the cO2Dosage is always in metric and should never be modified
        variation.cO2DosageUnitLabel =
          variation.cO2DosageLabel !== null &&
          variation.cO2DosageLabel !== undefined
            ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
            : ''
        const cO2DosageForVariationIdLabel =
          variation.cO2Dosage !== null
            ? variation.cO2DosageLabel?.toFixed(
                getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
              )
            : null
        variation.variationIdLabel = `${extractMixCodeFromVariationName(
          variation.mixCode
        )}-${variation.cementReductionPercent}-${cO2DosageForVariationIdLabel}${
          variation.cO2DosageUnitLabel
        }`
        return variation
      }
    )
    mixDesign.variations = variations
    mixDesign.isMetric = true
  } else if (!isMetric && isMetric !== mixDesign.isMetric) {
    mixDesign.designStrength = ConvertMPAToPSIUnrounded(
      mixDesign.designStrength
    )
    mixDesign.requiredStrength = ConvertMPAToPSIUnrounded(
      mixDesign.requiredStrength
    )
    mixDesign.averageStrength = ConvertMPAToPSIUnrounded(
      mixDesign.averageStrength
    )
    mixDesign.standardDeviation = ConvertMPAToPSIUnrounded(
      mixDesign.standardDeviation
    )
    mixDesign.cementitiousEfficiency = ConvertMPaKgToPSILbUnrounded(
      mixDesign.cementitiousEfficiency
    )
    mixDesign.cementAmountTotal = convertKgM3ToLbYd3Unrounded(
      mixDesign.cementAmountTotal
    )
    const variations = mixDesign.variations.map(variation => {
      variation.designStrength = mixDesign.designStrength
      variation.averageStrength = ConvertMPAToPSIUnrounded(
        variation.averageStrength
      )
      variation.requiredStrength = ConvertMPAToPSIUnrounded(
        variation.requiredStrength
      )
      variation.cementitiousEfficiency = ConvertMPaKgToPSILb(
        variation.cementitiousEfficiency
      )
      variation.cementAmountTotal = mixDesign.cementAmountTotal
      variation.isMetric = false
      variation.cO2DosageLabel = getCO2Dosage(
        variation.cO2Dosage,
        variation.cO2DosageUnit,
        isMetric
      )
      variation.cO2DosageUnitLabel =
        variation.cO2DosageLabel !== null &&
        variation.cO2DosageLabel !== undefined
          ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
          : ''
      const cO2DosageForVariationIdLabel =
        variation.cO2Dosage !== null
          ? variation.cO2DosageLabel?.toFixed(
              getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
            )
          : null
      variation.variationIdLabel = `${extractMixCodeFromVariationName(
        variation.mixCode
      )}-${variation.cementReductionPercent}-${cO2DosageForVariationIdLabel}${
        variation.cO2DosageUnitLabel
      }`
      return variation
    })
    mixDesign.variations = variations
    mixDesign.isMetric = false
  } else {
    return mixDesign
  }
  return mixDesign
}

export const convertMixVariationUnit = (
  isMetric: boolean,
  variation: MixGroupVariation
) => {
  if (isMetric && isMetric !== variation.isMetric) {
    variation.designStrength = ConvertPSIToMPAUnrounded(
      variation.designStrength
    )
    variation.averageStrength = ConvertPSIToMPAUnrounded(
      variation.averageStrength
    )
    variation.requiredStrength = ConvertPSIToMPAUnrounded(
      variation.requiredStrength
    )
    variation.cementitiousEfficiency = ConvertPSILbToMPAKgUnrounded(
      variation.cementitiousEfficiency
    )
    variation.cementAmountTotal = ConvertLbPerYd3ToKgPerM3Unrounded(
      variation.cementAmountTotal
    )
    variation.isMetric = true
    variation.cO2DosageLabel = getCO2Dosage(
      variation.cO2Dosage,
      variation.cO2DosageUnit,
      isMetric
    )
    variation.cO2DosageUnitLabel =
      variation.cO2DosageLabel !== null &&
      variation.cO2DosageLabel !== undefined
        ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
        : ''
    const cO2DosageForVariationIdLabel =
      variation.cO2Dosage !== null
        ? variation.cO2DosageLabel?.toFixed(
            getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
          )
        : null
    variation.variationIdLabel = `${extractMixCodeFromVariationName(
      variation.mixCode
    )}-${variation.cementReductionPercent}-${cO2DosageForVariationIdLabel}${
      variation.cO2DosageUnitLabel
    }`
    const convertedFreshProperties = variation.freshProperties.map(
      freshProperty => {
        freshProperty.slump = ConvertINCHtoMM(freshProperty.slump)
        freshProperty.concreteTemperature = ConvertFtoCUnrounded(
          freshProperty.concreteTemperature
        )
        freshProperty.ambientTemperature = ConvertFtoCUnrounded(
          freshProperty.ambientTemperature
        )
        freshProperty.batchCementContent = ConvertLbPerYd3ToKgPerM3Unrounded(
          freshProperty.batchCementContent
        )
        freshProperty.batchStrength = ConvertPSIToMPAUnrounded(
          freshProperty.batchStrength
        )
        freshProperty.co2DosageLabel = variation.cO2DosageLabel
        freshProperty.co2DosageUnitLabel = variation.cO2DosageUnitLabel
        freshProperty.isMetric = isMetric
        return freshProperty
      }
    )
    variation.freshProperties = convertedFreshProperties
    return variation
  } else if (!isMetric && isMetric !== variation.isMetric) {
    variation.designStrength = ConvertMPAToPSIUnrounded(
      variation.designStrength
    )
    variation.averageStrength = ConvertMPAToPSIUnrounded(
      variation.averageStrength
    )
    variation.requiredStrength = ConvertMPAToPSIUnrounded(
      variation.requiredStrength
    )
    variation.cementAmountTotal = convertKgM3ToLbYd3Unrounded(
      variation.cementAmountTotal
    )
    variation.cementitiousEfficiency = ConvertMPaKgToPSILbUnrounded(
      variation.cementitiousEfficiency
    )
    variation.isMetric = false
    variation.cO2DosageLabel = getCO2Dosage(
      variation.cO2Dosage,
      variation.cO2DosageUnit,
      isMetric
    )
    variation.cO2DosageUnitLabel =
      variation.cO2DosageLabel !== null &&
      variation.cO2DosageLabel !== undefined
        ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
        : ''
    const cO2DosageForVariationIdLabel =
      variation.cO2Dosage !== null
        ? variation.cO2DosageLabel?.toFixed(
            getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
          )
        : null
    variation.variationIdLabel = `${extractMixCodeFromVariationName(
      variation.mixCode
    )}-${variation.cementReductionPercent}-${cO2DosageForVariationIdLabel}${
      variation.cO2DosageUnitLabel
    }`
    const convertedFreshProperties = variation.freshProperties.map(
      freshProperty => {
        freshProperty.slump = ConvertMMtoINCHUnrounded(freshProperty.slump)
        freshProperty.concreteTemperature = ConvertCtoFUnrounded(
          freshProperty.concreteTemperature
        )
        freshProperty.ambientTemperature = ConvertCtoFUnrounded(
          freshProperty.ambientTemperature
        )
        freshProperty.batchCementContent = convertKgM3ToLbYd3Unrounded(
          freshProperty.batchCementContent
        )
        freshProperty.batchStrength = ConvertMPAToPSIUnrounded(
          freshProperty.batchStrength
        )
        freshProperty.co2DosageLabel = variation.cO2DosageLabel
        freshProperty.co2DosageUnitLabel = variation.cO2DosageUnitLabel
        freshProperty.isMetric = isMetric
        return freshProperty
      }
    )
    variation.freshProperties = convertedFreshProperties
    return variation
  } else {
    return variation
  }
}

export const convertAnalysisMixDataUnit = (
  isMetric: boolean,
  mixDesigns: Array<DigestedMixDesign>,
  selectedMixVariations: Array<MixGroupVariation>,
  selectedMixDesigns: Array<DigestedMixDesign>,
  compareAndAnalyzeMixDesigns: Array<DigestedMixDesign>
) => {
  const convertedMixDesigns = mixDesigns.map(mixDesign =>
    convertMixDesignUnit(isMetric, mixDesign)
  )
  const convertedSelectedVariations = selectedMixVariations.map(variation =>
    convertMixVariationUnit(isMetric, variation)
  )
  const convertedSelectedMixDesigns = selectedMixDesigns.map(mixDesign =>
    convertMixDesignUnit(isMetric, mixDesign)
  )
  const convertedCompareAndAnalyzeMixDesigns: ICompareAndAnalyzeMixDesigns = {}
  for (let mixDesignId in compareAndAnalyzeMixDesigns) {
    convertedCompareAndAnalyzeMixDesigns[mixDesignId] = convertMixDesignUnit(
      isMetric,
      compareAndAnalyzeMixDesigns[mixDesignId]
    )
  }
  return {
    convertedMixDesigns,
    convertedSelectedVariations,
    convertedSelectedMixDesigns,
    convertedCompareAndAnalyzeMixDesigns,
  }
}

export const convertAnalysisBatchDataUnit = (
  isMetric: boolean,
  batchData: any
) => {
  const convertedBatchData = batchData[0].value.map((batch: any) => {
    if (isMetric && isMetric !== batch.isMetric) {
      batch.slump = ConvertINCHtoMM(batch.slump)
      batch.cementContent = ConvertLbPerYd3ToKgPerM3(batch.cementContent)
      batch.strength = ConvertPSIToMPAUnrounded(batch.strength)
      batch.co2DosageLabel = getCO2Dosage(
        batch.co2Dosage,
        batch.co2DosageUnit,
        isMetric
      )
      batch.co2DosageUnitLabel = batch.co2DosageLabel
        ? getCO2DosageUnitLabel(batch.co2DosageUnit, isMetric)
        : ''
      const cO2DosageForVariationIdLabel =
        batch.cO2Dosage !== null
          ? batch.co2DosageLabel?.toFixed(
              getCO2DosagePrecision(batch.co2DosageUnit, isMetric)
            )
          : null
      batch.variationIdLabel = `${extractMixCodeFromVariationName(
        batch.mixCode,
        true
      )}-${cO2DosageForVariationIdLabel}${batch.co2DosageUnitLabel}`
      batch.isMetric = isMetric
      return batch
    } else if (!isMetric && isMetric !== batch.isMetric) {
      batch.slump = ConvertMMtoINCHUnrounded(batch.slump)
      batch.cementContent = convertKgM3ToLbYd3(batch.cementContent)
      batch.strength = ConvertMPAToPSIUnrounded(batch.strength)
      batch.co2DosageLabel = getCO2Dosage(
        batch.co2Dosage,
        batch.co2DosageUnit,
        isMetric
      )
      batch.co2DosageUnitLabel = batch.co2DosageUnitLabel
        ? getCO2DosageUnitLabel(batch.co2DosageUnit, isMetric)
        : ''
      const cO2DosageForVariationIdLabel =
        batch.cO2Dosage !== null
          ? batch.co2DosageLabel?.toFixed(
              getCO2DosagePrecision(batch.co2DosageUnit, isMetric)
            )
          : null
      batch.variationIdLabel = `${extractMixCodeFromVariationName(
        batch.mixCode,
        true
      )}-${cO2DosageForVariationIdLabel}${batch.co2DosageUnitLabel}`
      batch.isMetric = isMetric
      return batch
    } else {
      return batch
    }
  })
  return convertedBatchData
}

export function getMixDesignsForOptimization(
  compareAndAnalyzeMixDesigns: Array<DigestedMixDesign>
) {
  const mixDesignsForOptimization = []
  for (const key in compareAndAnalyzeMixDesigns) {
    const mixDesign = compareAndAnalyzeMixDesigns[key]
    const variations = mixDesign.variations || []

    //only include mixes that have a location, at least 2 variations & no optimized variation
    if (
      !!mixDesign.plantId &&
      variations.length > 1 &&
      !variations.some(variation => variation.variationType === 'Optimized')
    ) {
      mixDesignsForOptimization.push(mixDesign)
    }
  }
  return mixDesignsForOptimization
}

export function extractMixCodeFromVariationName(
  name: string,
  keepCementReduction: boolean = false
) {
  //variation name is mixCode-cementReduction-CO2Dosage, so the second last hyphen is the cutoff
  //not to use the first hypen in case some mixCodes have hyphens originally
  const hyphenIndex = keepCementReduction
    ? name.lastIndexOf('-')
    : name.lastIndexOf('-', name.lastIndexOf('-') - 1)

  if (hyphenIndex !== -1) {
    return name.slice(0, hyphenIndex)
  }
  return name
}

export function materialsToMaterialTableCards(
  arr: Array<IMaterial>,
  hashmap: Map<string, IMaterialTypeMetadata[]>
) {
  return arr
    .map(r => {
      const materialTableCard: any = {} // Use `any` type for dynamic properties
      const materialTypeMetadata = hashmap.get(
        (r.primaryMaterialType ?? '').toUpperCase()
      )

      if (!materialTypeMetadata) return null

      materialTypeMetadata.forEach(metadata => {
        const propertyName = metadata.kelownaKeyName
        const displayName = metadata.displayName

        let enumValue
        if (
          propertyName === 'primaryMaterialType' ||
          propertyName === 'materialType'
        ) {
          const materialOptions =
            propertyName === 'primaryMaterialType'
              ? MaterialTypeOptions
              : MaterialSubTypeOptions
          enumValue = materialOptions[r[propertyName]]
        }

        //Not to render Suppler Company & Supplier Plant for Cements
        if (
          (propertyName === 'supplierCompany' ||
            propertyName === 'supplierPlant') &&
          r['primaryMaterialType'] === MaterialTypeOptions.Cement
        ) {
          return
        }

        if (propertyName && r[propertyName] !== undefined) {
          materialTableCard[displayName] = enumValue ?? r[propertyName]
        }
      })

      return {
        id: r.materialMappingId,
        materialId: r.materialId,
        divisionId: r.divisionId,
        ...materialTableCard,
      }
    })
    .filter(Boolean) // Remove null or undefined elements from the array
}

export const getAnalysisIdentifyOutliersUrl = (
  tableData: IMixGroupVariationTableData,
  compareAndAnalyzeMixDesignsKeys: string[],
  selectedAge: number | string
) => {
  let path = ''
  const mixDesign =
    tableData.compareAndAnalyzeMixDesigns[compareAndAnalyzeMixDesignsKeys[0]]
  let variationsPath = ''
  if (
    mixDesign.variations.length <= 6 &&
    mixDesign.variations.length === tableData.selectedMixVariations.length
  ) {
    variationsPath = '&variationIds=all'
  }
  let intervalPath
  if (selectedAge && typeof selectedAge === 'number') {
    intervalPath = `&interval=${roundUpToDecimal(selectedAge * 24, 0)}`
  }
  tableData.selectedMixVariations.forEach(variation => {
    variationsPath = variationsPath + `&variationIds=${variation.variationId}`
  })
  path = `divisionId=${mixDesign.divisionId}&plantId=${mixDesign.plantId}&mixGroup=${mixDesign.mixDesignId}${variationsPath}`
  if (intervalPath) {
    path = path + intervalPath
  }
  return path
}

export function materialsToAddMixMaterialRows(arr: Array<IMaterial>) {
  return arr.map(r => {
    const materialSubtype = Object.entries(MaterialSubTypeOptions).find(
      ([key, value]) => key === r.materialType
    )
    const materialType = Object.entries(MaterialTypeOptions).find(
      ([key, value]) => key === r.primaryMaterialType
    )
    return {
      materialId: r.materialId,
      materialcells: [
        materialType?.[1], //use the value of the enum property
        materialSubtype?.[1], //use the value of the enum property
        r.supplierCompany,
        r.supplierPlant,
        r.aliases,
        r.quantity ? r.quantity : '',
        r.unit ? r.unit : '',
      ],
    }
  })
}

export function getMaterialName(mat: MaterialObject) {
  return mat?.alias?.length
    ? mat.alias[0]
    : `${mat.type}-${mat.subtype}-${mat.supplier}-${mat.plant}`
}

/** Check if a material is DefaultCement */
export const isDefaultCement = (material: IMaterial | IDigestedMaterial) => {
  return (
    material.primaryMaterialType === 'Cement' &&
    material.materialType === 'Cement' &&
    material.specificGravity === null &&
    material.supplierCompany === null &&
    material.supplierPlant === null &&
    material.cementSupplier === null
  )
}

export const isDefaultCO2 = (material: IMaterial | IDigestedMaterial) => {
  return (
    material.primaryMaterialType === 'CO2' &&
    material.materialType === 'CO2' &&
    material.specificGravity === null &&
    material.supplierCompany === null &&
    material.supplierPlant === null &&
    material.cementSupplier === null
  )
}

export const digestMaterials = (arr: Array<IMaterial>) => {
  return arr.map(material => {
    const frontendName = `${material.primaryMaterialType}-${material.materialType}-${material.supplierCompany}-${material.supplierPlant}`
    return {
      aliases: material.aliases,
      cementSupplier: material.cementSupplier,
      isIngested: material.isIngested,
      materialId: material.materialId,
      materialMappingId: material.materialMappingId,
      materialType: material.materialType,
      primaryMaterialType: material.primaryMaterialType,
      specificGravity: material.specificGravity,
      supplierCompany: material.supplierCompany,
      supplierPlant: material.supplierPlant,
      frontendName: frontendName,
      name: material?.aliases?.[0] ?? frontendName,
      title: material?.aliases?.[0] ?? frontendName,
      columnNumber: 1,
      isDefaultCement: isDefaultCement(material),
      isDefaultCO2: isDefaultCO2(material),
    }
  })
}

export function digestPlantComposition(
  data: Array<PlantComposition>,
  materialId: number | string
) {
  const clonedData: Array<PlantComposition> = cloneDeep(data)
  return (
    clonedData
      .filter(obj => {
        //only check the current composition
        return obj.cementCompositions[0]?.cementComponents.some(
          (component: CementComponent) => component.materialId === materialId
        )
      })
      //sort by plant name
      .sort((a, b) => a.plantName.localeCompare(b.plantName))
      //move current cement to the top
      .map(plant => {
        const currentComponents = plant.cementCompositions[0].cementComponents.filter(
          (component: CementComponent) => component.materialId === materialId
        )
        const otherComponents = plant.cementCompositions[0].cementComponents.filter(
          (component: CementComponent) => component.materialId !== materialId
        )
        plant.cementCompositions[0].cementComponents = [
          ...currentComponents,
          ...otherComponents,
        ]
        return plant
      })
  )
}

export const getMergeableMaterials = (
  materials: Array<IDigestedMaterial>,
  currentMaterial: IMaterial,
  materialIsUnclassified: boolean = false
) => {
  // Return non-ingested materials with the same material subtype, except for default cement / default CO2
  // Ignores material subtype comparison if material is unclassified
  if (materialIsUnclassified) {
    return materials
      .filter(mat => !mat.isIngested || mat.isDefaultCement || mat.isDefaultCO2)
      .sort((a, b) => {
        // Prioritize default cement and default CO2
        if (a.isDefaultCement || a.isDefaultCO2) {
          return -1
        } else {
          return 0
        }
      })
  } else {
    return materials
      .filter(
        mat =>
          mat.materialType === currentMaterial.materialType &&
          (!mat.isIngested || mat.isDefaultCement || mat.isDefaultCO2)
      )
      .sort((a, b) => {
        // Prioritize default cement and default CO2
        if (a.isDefaultCement || a.isDefaultCO2) {
          return -1
        } else {
          return 0
        }
      })
  }
}

/** Toggle an item in an array **/
//Add item if not already there, Remove item if it's existing
export const toggleItemInArray = <T>(item: T, array: T[]) => {
  const index = array.indexOf(item)
  if (index !== -1) {
    array.splice(index, 1)
  } else {
    array.push(item)
  }
  return array
}
